@tailwind base;
@tailwind components;
@tailwind utilities;

*{
	font-family: 'Karla', sans-serif;
	scroll-behavior: smooth;
}

.navbar-button{
	width:5rem;
	height:5rem;
	right:0.5rem;
	position: absolute;
}

@keyframes maincontent {
	0%{
		background-position:0 0 ;
	}
	50% {
		background-position: 100% 0;
	}
	100%{
		background-position:0 0 ;
	}
}

.home-content{
	background-image: linear-gradient(90deg, #e2e8f0, #ffedd5	,#e2e8f0);
	background-size: 300% 100%;
	animation: maincontent 10s ease-out infinite;

}

.effect-boy{
	text-decoration: underline #32557f;
}



.effect-boy:hover{
	text-decoration: underline #475569;
	transition-duration: 3s;
}

.full-center{
	position: absolute;
	top:50%;
	transform: translate(0%, -60%);
}

.bg-about-me{
	background-image: url("./images/wave-image.svg");
	background-repeat: repeat-x;
}

.about-me-width{
	width:75%;
	height:100%;
}

.experience-full-bg{
	background-size:cover;
	background-image: url("./images/bg-circle-1.svg");
	background-repeat: no-repeat;
}
.projects-full-bg{
	background-size:cover;
	background-image:url("./images/bg-blob-1.svg");
	background-repeat:no-repeat;
}

.project-item{
	box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
	transition: 0.1s;
	margin-top:1.25rem;
	margin-bottom:1rem;
	margin-right: 1.25rem;
	margin-left:1.25rem;
}
.project-item:hover{
	box-shadow: 0 30px 60px -20px rgb(0 0 0 / 0.25);
	transition: 0.1s;
	margin-top:1.15rem;
	margin-bottom:1.15rem;
}


@media (min-width:501px){
	.half-width{
		width:50%;
	}
}
@media (max-width:500px) {
	.half-width{
		width:80%;
	}
}

